angular.module('enervexSalesappApp').factory('SelectionMetaUtil', function SelectionMetaUtil(_, Util) {
	return {
		getSelectionMeta: function(vent, design, defaultProductType) {
			var value = _.find(design.stack.selectionMetas, function(selectionMeta){
				// return vent.GUIident == selectionMeta.GUIident && selectionMeta.productType == _productType
				if (vent.ID == selectionMeta.ID) {
					return selectionMeta
				// } else if (vent.GUIidentPath && vent.GUIidentPath != '' && vent.GUIidentPath == selectionMeta.GUIidentPath) {
				// 	console.log("getting selection meta by GUIidentPath")
				// 	return selectionMeta
				} else {
					return false
				}
			})
			if (!value) {
				value = {
					ID: vent.ID,
					GUIidentPath: vent.GUIidentPath,
					startingConfig: 'type',
					productType: defaultProductType,
					productSubtype: _.get(design, "application.stack.defaultStartingPoint"),
					startingPointProduct: null,
					// sawtooth:design.application.properties.sawtoothEnabled
					sawtooth:false
				}
				design.stack.selectionMetas.push(value)
			}
			if (vent.ID && vent.ID != '') {
				value.ID = vent.ID
			}
			if (vent.GUIidentPath && vent.GUIidentPath != '') {
				value.GUIidentPath = vent.GUIidentPath
			}
			if (!value.lengthFitment) {
				value.lengthFitment = "5852e417ae7e00aa496ebb70"
			}
			return value
		},
		cloneMeta: function(meta) {
			var clone = _.clone(meta)
			delete meta.ID
			delete meta.GUIidentPath
			delete meta.GUIident
			return clone
		},
		getSelectionMetas: function(design, allProductTypes) {
			// console.log('getFittingMetas')
			if (!design) {
				return
			}
			if (!design.include.stack){
				return
			}
			var self = this
			// console.log('getFittingMetas() starting with '+ design.stack.fittingMetas.length)
			_.each(design.fc5layout.Layout, function(v){
				if (Util.isTermination(v)) {
					var selectionMeta = self.getSelectionMeta(v, design, Util.getProductType('TerminationPoint',allProductTypes)._id)
				} else {
					var selectionMeta = self.getSelectionMeta(v, design, null)
				}
			})
			// console.log('getFittingMetas() completed with '+ design.stack.fittingMetas.length)
		},
	}
})
